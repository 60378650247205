import { formatDate } from '@angular/common';

/**
 * Transforms a date into a string in a specific format
 * @param date - The date to transform
 * @param format - The format in which to transform the date. Defaults to dd/MM/yyyy
 */
export function dateToString(date?: Date, format?: string): string {
  date = date ? date : new Date();
  format = format ? format : 'dd/MM/yyyy';

  return formatDate(date, format, 'es-ES');
}

export function formatDateTime(date: Date): string {
  const dateString = date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
  const timeString = date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });

  return `${dateString} - ${timeString}`;
}
